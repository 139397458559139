import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfProduct from "@/vue/domain/product/df-product";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfBrand from "@/vue/domain/brand/df-brand";
import { Carousel, Slide } from "vue-carousel-variable-width";

@Component({
  components: {
    Carousel,
    Slide,
  },
})
export default class DfProductDetailsImagesComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  @Prop({ type: Object, default: () => ({}) })
  product!: DfProduct;

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get brandLogoImageUrl(): string {
    return Utils.getPropertyImageUrl(this.brand, Utils.PROPERTY_LOGO, `${Utils.getPublicPath()}/assets/logo.png`);
  }

  get brandLogosUrl(): Array<string> {
    return Utils.getPropertyImagesUrl(this.product, Utils.PROPERTY_PRODUCT_BRAND_LOGOS);
  }

  get propertyLogosUrl(): Array<string> {
    return Utils.getPropertyImagesUrl(this.product, Utils.PROPERTY_PRODUCT_PROPERTY_LOGOS);
  }

  get previewesUrl(): Array<string> {
    return Utils.getPropertyImagesUrl(this.product, Utils.PROPERTY_PRODUCT_PREVIEW);
  }

  get missingPreviewUrl(): string {
    return this.previewesUrl.length === 0 ? this.brandLogoImageUrl : this.previewesUrl[0];
  }
}
